import React, { Fragment, useState } from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import Carousel from 'nuka-carousel';

import { useTailwindConfig, useMediaQuery } from '../../hooks';
import './ProjectCarousel.css';

const ProjectCarousel = ({ allFile, bgGrey }) => {
  const tailwindConfig = useTailwindConfig();

  const isSmall = useMediaQuery(
    `(max-width: ${tailwindConfig.theme.screens.lg})`
  );

  const slidesToShow = isSmall ? 1 : 2;
  const [index, setIndex] = useState(0);

  return (
    <div
      id="carousel"
      className={`w-full px-4 py-16 ${bgGrey ? 'bg-gray-200' : ''} sm:px-0`}
    >
      <h2 className="mb-12 text-3xl font-bold tracking-wider text-center uppercase text-light-blue">
        Recent Projects
      </h2>
      <Carousel
        autoplay
        afterSlide={(currentIndex) => setIndex(currentIndex)}
        autoplayInterval={5000}
        cellAlign="center"
        dragging
        easing="easeCubicInOut"
        enableKeyboardControls
        slidesToShow={slidesToShow}
        swiping
        transitionMode="scroll3d"
        wrapAround
        renderCenterLeftControls={({ previousSlide }) => (
          <button
            aria-label="Previous slide"
            className="px-4 py-2 leading-none bg-white"
            onClick={previousSlide}
            type="button"
          >
            <FaAngleDoubleLeft className="text-light-blue" />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button
            aria-label="Next slide"
            className="px-4 py-2 leading-none bg-white"
            onClick={nextSlide}
            type="button"
          >
            <FaAngleDoubleRight className="text-light-blue" />
          </button>
        )}
        renderBottomCenterControls={null}
        className="w-full h-full mx-auto overflow-visible outline-none focus:ring max-w-7xl"
      >
        {allFile.map((node, i) => (
          <Fragment key={node.img.id}>
            <div className="relative h-0 aspect-ratio-3/4 sm:aspect-ratio-16/9">
              <div className="absolute inset-0 flex">
                <Image
                  fluid={node.img.childImageSharp.fluid}
                  className="flex-1"
                />
              </div>
            </div>
            <p
              className={`${
                i === index ? '' : 'text-transparent'
              } w-full max-w-md pb-12 mx-auto mt-8 tracking-wider text-center transition duration-300 ease-in-out md:max-w-xl`}
            >
              {node.description}
            </p>
          </Fragment>
        ))}
      </Carousel>
    </div>
  );
};

ProjectCarousel.propTypes = {
  allFile: PropTypes.array.isRequired,
  bgGrey: PropTypes.bool,
};

export default ProjectCarousel;
